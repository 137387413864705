import React, {useState} from 'react';
import Tag from "./Tag";

const TaskForm = ({ clickModels,setShowModal }) => {
  console.log("TaskForm");
  console.log(clickModels)
  clickModels.forEach(mesh => {
        console.log(mesh.data.foid)
      })
  let taskobject = clickModels[0].data

  return (

    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">

    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div className="modal-container bg-white w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

      <div className="modal-content py-4 text-left px-6">

        <span className="close-modal absolute top-0 right-0 cursor-pointer z-50" onClick={() => setShowModal(false)}>&times;</span>

        <div className="mb-4">
          <h2 className="text-2xl font-bold">Task</h2>
        </div>

       {clickModels.map(mesh => (
    <div key={mesh.data.foid}>
         <div className="mb-4">
        <label htmlFor="taskDesignation" className="block text-sm font-medium text-gray-700">Designation:</label>
        <select id="taskDesignation" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].type} >
             <option value="">------</option>
          <option value="design">Design</option>
          <option value="procurement">Procurement</option>
          <option value="fabrication">Fabrication</option>
           <option value="delivery">Delivery</option>
           <option value="insulation">Insulation</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700">Assigned To:</label>
        <input type="text" id="assignedTo" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].assignedTo}  />
      </div>


        <div className="mb-4">
        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date:</label>
        <input type="date" id="startDate" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].startDate}  />
      </div>

      <div className="mb-4">
        <label htmlFor="targetDate" className="block text-sm font-medium text-gray-700">Target Date:</label>
        <input type="date" id="targetDate" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].targetDate}  />
      </div>

      <div className="mb-4">
       <label htmlFor="targetDate" className="block text-sm font-medium text-gray-700">Tag 1:</label> <Tag></Tag>
       <label htmlFor="targetDate" className="block text-sm font-medium text-gray-700">Tag 2:</label>  <Tag></Tag>
      </div>

      <div className="mb-4">
        <label htmlFor="taskNotes" className="block text-sm font-medium text-gray-700">Task Notes:</label>
        <textarea id="taskNotes" rows="4" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].taskNotes} ></textarea>
      </div>

      <div className="mb-4">
        <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status:</label>
        <select id="status" className="mt-1 p-2 w-full border border-gray-300 rounded" value={mesh.data.taskDetails[0].status} >
          <option value="todo">To Do</option>
          <option value="inProgress">In Progress</option>
          <option value="inProgress">Checking</option>
          <option value="completed">Completed</option>
        </select>
      </div>
    </div>
                  ))}

        <div className="flex justify-end">
          <button className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">Submit</button>
          <button onClick={() => setShowModal(false)} className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors">Close</button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default TaskForm;
