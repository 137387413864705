const Layer = ({ layerNames, model }) => {
  const handleLayerClick = (layerName) => {
    model.traverse((child) => {
      if (
        child.userData.attributes &&
        child.userData.attributes.userStrings &&
        child.userData.attributes.userStrings.length > 0
      ) {
        if (child.userData.attributes.layerIndex === layerName) {
          child.visible = !child.visible;
        }
      }
    });
  };

  return (
    <div>
      <h1>Layers</h1>
      {/* Map over layer names and create a button for each */}
      {layerNames.map((layerName, index) => (
        <div key={index}> {/* Assign a unique key to each button */}
          <button onClick={() => handleLayerClick(index)}>
            {layerName}({index})
          </button>
        </div>
      ))}
    </div>
  );
};

export default Layer;
