import React, { useState } from 'react';

const DropdownButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="px-4 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:bg-gray-700"
      >
        {selectedOption || 'Select Option'}
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          {/* Dropdown items */}
          <a
            href="#"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
            onClick={() => handleOptionClick('In Progress')}
          >
            In Progress
          </a>
          <a
            href="#"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
            onClick={() => handleOptionClick('Done')}
          >
            Done
          </a>
          <div className="relative">
            <a
              href="#"
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              onClick={() => handleOptionClick('Error')}
            >
              Error
            </a>
            {/* Nested dropdown */}
            {selectedOption === 'Error' && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <a
                  href="#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => console.log('Error Option 1 clicked')}
                >
                  Error Option 1
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => console.log('Error Option 2 clicked')}
                >
                  Error Option 2
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
