import React from 'react';
import ChatBox from './ChatBox'; // Assuming ChatBox is imported from the correct path

const Properties = ({ clickedModel, storefoid, dummy }) => {
  let commentobject = [];
  let taskobject = [];

  // Assuming the logic for commentobject and taskobject assignment remains the same

  return (
      <div className="flex-grow overflow-y-auto p-4 bg-white">
        <div >
          {clickedModel && clickedModel.map((model, modelIndex) => (
    <div key={modelIndex} className="bg-white rounded-lg border border-amber-100 shadow-sm">
        <table className="min-w-full divide-y divide-gray-200">
            <tbody className="divide-y divide-gray-200">
                   {model.additionalInfo &&
                  model.additionalInfo.map((attribute, index) => (
                    attribute[0] === 'pn_number' ? (
                     <tr key={index} className="bg-gray-500 text-cyan-50">
                      <td className="px-2 py-1 font-medium text-white">{attribute[0]}</td>
                     <td className="px-2 py-1 text-white flex items-center justify-between">
                      <span>{attribute[1]}</span>
                      <button className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded">
                        + Task
                      </button>
                    </td>
                    </tr>
                    ) : null
                  ))
                }

                {model.children &&
                model.userData.attributes.userStrings &&
                model.userData.attributes.userStrings.map((attribute, index) => (
                     attribute[0] !== '$block-instance-original-object-id$' ? (
                    <tr key={index}>

                        <td className="px-2 py-1 font-medium text-gray-600">{attribute[0]}</td>
                        <td className="px-2 py-1 text-gray-900">
                            {' '}
                            {attribute[0] === 'foid' ? (storefoid = attribute[1]) : attribute[1]}
                        </td>
                    </tr>
                     ):null
                ))
                }
                     {model.additionalInfo &&
                  model.additionalInfo.map((attribute, index) => (
                    attribute[0] !== 'pn_number' ? (
                      <tr key={index} className="">
                        <td className="px-2 py-1 font-medium text-gray-900">{attribute[0]}</td>
                        <td className="px-2 py-1 text-gray-900">{attribute[1]}

                        </td>
                      </tr>
                    ) : null
                  ))
                }
                {!model.userData.attributes.userStrings && (
                    <tr>
                        <td colSpan="2" className="text-center text-gray-500 px-2 py-1">
                            No additional properties
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
))}

        </div>
      </div>
  );
};

export default Properties;
