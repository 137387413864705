import React, { useState } from 'react';
import ChatBox from "./ChatBox";
import Properties from "./Properties";
import Layer from "./Layer";
import TaskDetail from "./TaskDetail";
const Tabs = ({clickedModel, storefoid, dummy,model,layerNames}) => {
  const [activeTab, setActiveTab] = useState(0);


let taskobject = [];
let commentobject = [];
let foundFoidValueList = storefoid; // Assuming foundFoidValue is an array



dummy.forEach(task => {
    if (foundFoidValueList.includes(task.foid)) {
        clickedModel.forEach(child => {
            if (child.additionalInfo){
            const hasPnNumber = child.additionalInfo.some(entry => entry[0] === "pn_number");
           let foidValue = null; // Initialize foidValue to null
            child.userData.attributes.userStrings.some(attribute => {
                if (Array.isArray(attribute) && attribute.includes("foid")) {
                    const foidIndex = attribute.findIndex(item => item === "foid");
                    foidValue = attribute[foidIndex + 1];
                    return true; // Signal that "foid" was found and exit the loop
                }
                return false; // Signal that "foid" was not found in this attribute and continue iterating
            });
            console.log(foidValue)
            if (hasPnNumber &&foidValue ==task.foid ) {
                // Clone the additionalInfo array using the spread operator
                task.taskDetails.additionalInfo = [...child.additionalInfo];
                task.additionalInfo = [...child.additionalInfo];
            }
            }
        });
        commentobject.push(task);
        taskobject.push(task.taskDetails);
           console.log("task");
        console.log(task);
           console.log("task");
    }
});



// Now taskobject contains task details for every matching task.foid in foundFoidValueList


  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="">
      <div className="flex">
        <button
          className={`px-4 py-2 rounded-t-lg border border-b-0 ${activeTab === 0 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick(0)}
        >
         Properties
        </button>
        <button
          className={`px-4 py-2 rounded-t-lg border border-b-0 ${activeTab === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick(1)}
        >
                   Task
        </button>
        <button
          className={`px-4 py-2 rounded-t-lg border border-b-0 ${activeTab === 2 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick(2)}
        >
            Layer

        </button>
            <button
          className={`px-4 py-2 rounded-t-lg border border-b-0 ${activeTab === 3 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick(3)}
        >
         Comment
        </button>
      </div>
      <div className="border border-t-0 rounded-b-lg">
          {/*Properties*/}
        <div className={`${activeTab === 0 ? 'block' : 'hidden'}`}>
           <Properties clickedModel={clickedModel} storefoid={storefoid} dummy={dummy} ></Properties>
             </div>
        <div className={`${activeTab === 1 ? 'block' : 'hidden'}`}>
          <TaskDetail tasks={taskobject}></TaskDetail>
        </div>
        <div className={`${activeTab === 2 ? 'block' : 'hidden'}`}>
        <div className="bg-gray-50 h-1/2">
           <Layer layerNames={layerNames} model={model}></Layer>
        </div>
      </div>
           <div className={`${activeTab === 3 ? 'block' : 'hidden'}`}>
        <div className="bg-gray-50 h-1/2">
        <ChatBox tasks={commentobject} />
        </div>
      </div>
      </div>
    </div>
  );
};

export default Tabs;
