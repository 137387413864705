import { useState, useEffect,useLayoutEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { Rhino3dmLoader } from "three/examples/jsm/loaders/3DMLoader";
import * as THREE from "three";
import { EdgesGeometry, LineBasicMaterial, LineSegments, MeshPhongMaterial } from "three";
import staticVariables from './staticVariables';
import utilities from "./utilities";

function Rhino3dmLogo({ url, color = "black", onClick,onLayersLoaded, getModel,onShowAllMeshClick, ...props }) {
    const [selectedObjects, setSelectedObjects] = useState([])
     const [selectedObjectsPass, setselectedObjectsPass] = useState([]);

    const utilities = require('./utilities.js');


  const model = useLoader(Rhino3dmLoader, url, (loader) =>
    loader.setLibraryPath("https://cdn.jsdelivr.net/npm/rhino3dm@0.15.0-beta/")
  );
  let init = false;

//    model.children.forEach(child => {
//        if(child.isObject3D)
//        {
//            child.children.forEach(child1 => {
//                  if(child1.isPoints)
//                     {
//                         console.log(child1);
//                     }
//            });
//        }
//     // Perform some action with each child here
// });

const sprites = model.children.filter(child => child.isSprite);
//console.log("sprites");
//console.log(sprites);
//console.log("sprites");
  // Define the handleLayersLoaded function to be passed as a prop
  const handleLayersLoaded = () => {
    if (onLayersLoaded && model && model.userData && model.userData.layers) {
      const layerNames = model.userData.layers.map((layer) => layer.name);
      onLayersLoaded(layerNames,model); // Callback to the parent component with the layer names
      init = true;
    }
  };
  useEffect(() => {
    if (!init) {
      handleLayersLoaded();
    }

  }, []); // Empty dependency array to run only once on component mount



useLayoutEffect(() => {
  model.traverse((child) => {
      if(child.isSprite){
        child.scale.x =  child.scale.x*100
          child.scale.y =  child.scale.y*100
      }
    if (child.isMesh) {
      // Set up the transparent material similar to Rhino's ghosted view
        child.material = staticVariables.unhoveredghostedMaterial;
        const userStrings = child.userData.attributes.userStrings;
        if (child.userData.attributes.userStrings && userStrings.length > 0)
        {
           for (const attribute of userStrings) {
            if (attribute[0] === "foid" && staticVariables.inProgressUUIDs.includes(attribute[1])) {
                child.material = staticVariables.inProgressghostedMaterial;
                break;
            }
                if (attribute[0] === "foid" && staticVariables.ErrorUUIDs.includes(attribute[1])) {
                child.material = staticVariables.errorGhostedMaterial;
                break;
            }

            }
         }

      // Create edges
      const edges = new EdgesGeometry(child.geometry);
      const edgesMaterial = new LineBasicMaterial({ color: "black", linewidth: 2 }); // Edges are black
      const edgeLines = new LineSegments(edges, edgesMaterial);
      child.add(edgeLines);
      // Configure shadows if your scene has lighting that supports it
      child.castShadow = true;
      child.receiveShadow = true;
    }


  }
  );
}, [model]);

  const handleClick = (e) => {
    e.stopPropagation();
    const shiftPressed = e.shiftKey;
    // Check if an object is clicked
    if (e.object) {
        const tolerance = 100; // Adjust tolerance as needed
        const boundingBox = new THREE.Box3().setFromObject(e.object);
        const center = new THREE.Vector3();
        boundingBox.getCenter(center);

        const rhinoCenter = new THREE.Vector3(center.x, center.z, center.y);
        sprites.forEach(sprite => {
        const spritePosition = sprite.position.clone(); // Clone the sprite's position
        const distance = rhinoCenter.distanceTo(spritePosition); // Calculate distance between sprite position and center
        if (distance <= tolerance) {
        e.object.additionalInfo = sprite.userData.attributes.userStrings;
        }
        });

        // Clear previous selections if Shift is not pressed
        if (!shiftPressed) {
            selectedObjects.forEach(obj => {
                obj.material = staticVariables.unhoveredghostedMaterial; // Deselect visually
            });
        }

        // Check if the clicked object has userStrings
        if (e.object.userData.attributes.userStrings) {
            const userStrings = e.object.userData.attributes.userStrings;
            // Check if any userString includes "foid"
            const hasFoid = userStrings.some(attribute => Array.isArray(attribute) && attribute.includes("foid"));
            const FoidValue =     utilities.getFoidValue(userStrings);
            const objectsWithSameFoid = utilities.getObjectsByFoid(staticVariables.dummy, FoidValue);
             console.log("**************");
            console.log(FoidValue);
            console.log(objectsWithSameFoid);
             console.log("**************");
            // Toggle selection with Shift key
            if (shiftPressed) {
                if (FoidValue !== null) {
                    setSelectedObjects(prevSelected => {
                        e.object.data =objectsWithSameFoid[0]
                        const updatedSelected = prevSelected.includes(e.object)
                            ? prevSelected.filter(so => so !== e.object)
                            : [...prevSelected, e.object];
                        return updatedSelected;
                    });
                    e.object.material = staticVariables.hoveredghostedMaterial; // Apply ghosted material on click
                    if (onClick) onClick(selectedObjects); // Pass clicked object to onClick handler
                }
            } else { // If Shift key is not pressed
                if (FoidValue !== null) {
                    e.object.data =objectsWithSameFoid[0]
                    setSelectedObjects([e.object]);
                    e.object.material = staticVariables.hoveredghostedMaterial; // Apply ghosted material on click
                    if (onClick) onClick([e.object]); // Pass clicked object to onClick handler



                }
            }
        }
    }
console.log("########################")
    console.log(e.object)
console.log("########################")

};


   useEffect(() => {
    // This code will run after every render,
    // similar to a callback after state is updated
    console.log('State updated:', selectedObjects);
     if (onClick) onClick(selectedObjects );
    // You can perform other actions here, such as an API call
    // Example: makeApiCall(searchTerm);
  }, [selectedObjects]); // The effect depends on the searchTerm state
  return (

         <primitive
        onPointerOver={(e) => {
            if (e.object.isMesh) {
            e.stopPropagation()
                if (e.object.userData.attributes.userStrings) {
                    const userStrings = e.object.userData.attributes.userStrings;
                    for (const attribute of userStrings) {
                        if (Array.isArray(attribute) && attribute.includes("foid")) {
                        e.object.material = staticVariables.hoveredghostedMaterial
                        }
                    }
                }
            }
        }}


      onPointerOut={(e) => {
        if (e.object.isMesh) {
           if (selectedObjects.indexOf(e.object) === -1) {
                e.stopPropagation()
                e.object.material = staticVariables.unhoveredghostedMaterial
                 const userStrings = e.object.userData.attributes.userStrings;
        if (e.object.userData.attributes.userStrings && userStrings.length > 0)
        {

           for (const attribute of userStrings) {
            if (attribute[0] === "foid" && staticVariables.inProgressUUIDs.includes(attribute[1])) {
                e.object.material = staticVariables.inProgressghostedMaterial;
                break;
            }
                if (attribute[0] === "foid" && staticVariables.ErrorUUIDs.includes(attribute[1])) {
                e.object.material = staticVariables.errorGhostedMaterial;
                break;
            }

            }

         }
               }
           }

      }}
          onClick={(e) => handleClick(e)}
      object={model}
      {...props}
    />

  );
}


export default Rhino3dmLogo;
