import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import staticVariables from "../components/staticVariables";
// Check the imports match your project structure

const transformDataToColumns = (data) => {
  const columns = {
    todo: { name: 'To Do', items: [] },
    inProgress: { name: 'In Progress', items: [] },
        Validation: { name: 'Validation', items: [] },
    completed: { name: 'Done', items: [] }
  };

  for (const group of data) {
    for (const task of group.taskDetails) {
      const status = task.status === 'completed' ? 'completed' : task.status; // Handle any variations in status here
      columns[status].items.push({
        id: `task-${task.id}`, // Ensuring the id is unique and a string
        content: task.taskNotes,
        tag1: task.tag1,
        tag2: task.tag2,
        data: task
        // Include any other task properties you want to display or use
      });
    }
  }

  return columns;
};


const initialColumns = transformDataToColumns(staticVariables.dummy);

console.log(initialColumns);
const KanbanPage = () => {
  const [columns, setColumns] = useState(initialColumns);

  const onDragEnd = result => {
    const { source, destination } = result;
    // Added check to see if destination is null (item dropped outside a droppable area)
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return; // No movement happened
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      const newItems = Array.from(start.items);
      newItems.splice(source.index, 1);
      const [movedItem] = newItems.splice(source.index, 1);
newItems.splice(destination.index, 0, movedItem); // Corrected


      const newColumn = { ...start, items: newItems };
      setColumns(prev => ({
        ...prev,
        [source.droppableId]: newColumn
      }));
    } else {
      // Moving from one list to another
      const startItems = Array.from(start.items);
      const finishItems = Array.from(finish.items);
      const [movedItem] = startItems.splice(source.index, 1);
      finishItems.splice(destination.index, 0, movedItem);

      const newStart = { ...start, items: startItems };
      const newFinish = { ...finish, items: finishItems };

      setColumns(prev => ({
        ...prev,
        [source.droppableId]: newStart,
        [destination.droppableId]: newFinish
      }));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px' }}>
        {Object.keys(columns).map(key => (
          <Droppable key={key} droppableId={key}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ margin: '0 10px', minWidth: '300px', border: '1px solid lightgray', padding: '10px', backgroundColor: '#f8f8f8' }}
              >
                <h3>{columns[key].name}</h3>
                {columns[key].items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: "none",
                          padding: "16px",
                          margin: "0 0 8px 0",
                          backgroundColor: "#ffffff",
                          ...provided.draggableProps.style
                        }}
                      >
                        <b>{item.id} </b>
                        {item.content}
                      <div>
  <span className="flex items-center">
  {item.data.tag1 && (
    <span className="ml-1 bg-green-600 rounded min-w-6 text-center">{item.data.tag1}</span>
  )}
  {item.data.tag2 && (
    <span className="ml-1 bg-green-200 rounded min-w-6 text-center">{item.data.tag2}</span>
  )}
    <span className="rounded-full bg-blue-500 text-white w-3 h-3 flex items-center justify-center">
      {item.data.assignedTo.charAt(0)}
    </span>
    <span className="ml-1">{item.data.assignedTo}</span>

  </span>
</div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default KanbanPage;
