import React from 'react';

const TaskDetail = ({ tasks }) => {
      const getColor = (status) => {
    switch (status) {
      case 'todo':
        return 'red';
      case 'inProgress':
        return 'blue';
      case 'checking':
        return 'orange';
      case 'completed':
        return 'green';
      default:
        return 'black'; // Default color if status doesn't match
    }
  };

  return (
     <div className="flex-grow overflow-y-auto p-4">
  {tasks.map((taskArray, arrayIndex) => (
    <div key={arrayIndex} className="space-y-4">
      <table className="min-w-full divide-y divide-gray-200 ">
                 {taskArray.additionalInfo &&
                  taskArray.additionalInfo.map((attribute, index) => (
                    attribute[0] === 'pn_number' ? (
                      <tr key={index} className="bg-gray-500 text-cyan-50">
                        <td className="px-2 py-1 font-medium text-white">{attribute[0]}</td>
                        <td className="px-2 py-1 text-white">{attribute[1]}</td>
                      </tr>
                    ) : null
                  ))
                }
        {taskArray.map((task, index) => (
          <tbody key={index} className="divide-y divide-gray-200 border space-y-4 ">
            <tr>
              <td className="px-2 py-1 font-medium text-gray-600 border-t-4">AssignedTo:</td>
            <td className="px-2 py-1 text-gray-900 border-t-4">{task.assignedTo} | {task.taskDesignation}
                | <span style={{ color: getColor(task.status) }}>{task.status}</span></td>
            </tr>
      <tr>
  <td className="px-2 py-1 font-medium text-gray-600">Date:</td>
  <td className="px-2 py-1">
    <div className="flex">
      <div className="w-1/2">Start : {task.startDate}</div>
      <div className="w-1/2">End : {task.targetDate}</div>
    </div>
  </td>
</tr>

            <tr>
              <td className="px-2 py-1 font-medium text-gray-600">Tag 1:</td>
              <td className="px-2 py-1 text-gray-900">{task.tag1}</td>
            </tr>
            <tr>
              <td className="px-2 py-1 font-medium text-gray-600">Tag 2:</td>
              <td className="px-2 py-1 text-gray-900">{task.tag2}</td>
            </tr>

              <tr>
              <td className="px-2 py-1 font-medium text-gray-600">Notes:</td>
              <td className="px-2 py-1 text-gray-900">{task.taskNotes}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  ))}

 </div>
  );
};

export default TaskDetail;
