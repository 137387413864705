import {React, useState, useRef, useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import { Canvas , useFrame} from '@react-three/fiber';
import { OrbitControls, StatsGl,Stats  } from '@react-three/drei';
import Rhino3dmLogo from "../components/Rhino3dmLogo";
import { Euler } from "three";
import DropdownButton from "../components/StatusDropDown";
import TaskForm from "../components/TaskForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'; // Import the icons you want to use
import '../index.css';
import Tabs from "../components/Tabs";
import staticVariables from "../components/staticVariables";

function HomePage() {
    const [clickedModel, setClickedModel] = useState(null);
    const [model, setModel] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [foidValue, setFoidValue] = useState('');
    const [layerNames, setLayerNames] = useState([]);
    const rhinoToThreeRotation = new Euler(-Math.PI / 2, 0, 0);
    let commentobject = [];
    let taskobject = [];
    let storefoid = "";


  const handleLayersLoaded = (names,orgModel) => {
    // Set the layer names received from the Rhino3dmLogo component
    setLayerNames(names);
    setModel(orgModel)
  };



  const handleModelClick = (selectedObjects) => {
    if (selectedObjects.length > 0) {
        setClickedModel(selectedObjects);
        const foundFoidValues = [];
        selectedObjects.forEach(object => {
            const foundFoidValue = object.userData.attributes.userStrings.find(([key, value]) => key === 'foid')?.[1];
            if (foundFoidValue) {
                foundFoidValues.push(foundFoidValue);
            }
        });
        setFoidValue(foundFoidValues.length > 0 ? foundFoidValues : '');
    }
};


const hideMeshe = (model) => {
        clickedModel.forEach(mesh => {
  const foundValue = mesh.userData.attributes.userStrings.find(([key, value]) => key === '$block-instance-original-object-id$')?.[1];
    if(foundValue){
          mesh.parent.visible = false;
    }
    else{
         mesh.visible = false;
    }
          })
};

const isolate = () => {
    console.log(model);
    console.log("isolate")
    let modelMeshs = []
      model.children.forEach(child => {
        console.log(child);
        if(child.isObject3D){
            child.children.forEach(child1 => {
                if(child1.isMesh){
                    modelMeshs.push(child1)
                    if(!clickedModel.includes(child1))
                    {
                        const foundValue = child1.userData.attributes.userStrings.find(([key, value]) => key === '$block-instance-original-object-id$')?.[1];
                        if (foundValue) {
                        child1.parent.visible = false;
                        } else {
                        child1.visible = false;
                        }
                    }

                }
            })
        }
    })



};


const handleShowAllMesh = () => {
  // Check if model is not null and it has children
    //console.log("showAllMesh");
    //console.log(model);
       // console.log("showAllMesh");
  if (model && model.children) {
    // Iterate over the children array
     // console.log("ABC")
    model.children.forEach((child) => {
      child.visible = true;

    });
  }
};


const handleModelDoubleClick = () => {
  setShowModal(true);
};


  return (
<div>
    <div className="flex content" >
      <div className="w-3/4 bg-gray-200">
<div className="flex items-center space-x-2">
 <button className="bg-gray-500 text-white px-1 py-1 rounded hover:bg-blue-600 transition-colors" onClick={hideMeshe}>
  <FontAwesomeIcon icon={faEyeSlash} />
</button>
<button onClick={handleShowAllMesh} className="bg-yellow-200 text-black px-1 py-1 rounded hover:bg-blue-600 transition-colors">
  <FontAwesomeIcon icon={faEye} />
</button>
     <button className="bg-gray-500 text-white px-1 py-1 rounded hover:bg-blue-600 transition-colors" onClick={isolate}>
  <FontAwesomeIcon icon={faEyeSlash} /> isolate
</button>
      <input type="text" placeholder="Type your command" />
    <div>
      <DropdownButton />
    </div>
</div>

 <Canvas camera={{
    position: [0, 20, 5000], // Adjust as necessary to fit your model
    up: [0, 1, 0],
    fov: 75, // Typical FOV value
    near: 0.1, // The near clipping plane
    far: 100000, // The far clipping plane, set as necessary to include the entire model
  }}
>

       <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 200]} intensity={1} />
      {/* Load the 3D model */}

      <Rhino3dmLogo
        url="/test_wireframe02.3dm"
        color="9CAFAA"
        onClick={handleModelClick}
        rotation={[rhinoToThreeRotation.x, rhinoToThreeRotation.y, rhinoToThreeRotation.z]}
        onDoubleClick={handleModelDoubleClick} // Add onDoubleClick event handler
        onLayersLoaded={handleLayersLoaded}
      />
      <OrbitControls target={[0, 0, 0]} enableDamping dampingFactor={0.1} rotateSpeed={2} zoomSpeed={2} />
      {/*<StatsGl />*/}
    </Canvas>


      </div>
    <div className="relative w-1/4 content flex flex-col overflow-y-auto">
    <Tabs commentobject={commentobject} clickedModel={clickedModel} dummy={staticVariables.dummy} storefoid={foidValue} model={model} layerNames={layerNames}></Tabs>
    </div>
</div>

{showModal && (
 <TaskForm clickModels={clickedModel} data={staticVariables.dummy} setShowModal={setShowModal}></TaskForm>
)}

        </div>
  );
}

export default HomePage;