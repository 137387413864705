import React from 'react';

const ChatBox = ({ tasks }) => {
  console.log("ChatBox")
    console.log(tasks)
  return (
    <>
      {tasks.map((task, index) => (
        <div key={index} className="border rounded p-4 shadow-md flex flex-col h-full relative">
          {/* Chat content goes here */}
 {task.additionalInfo &&
                  task.additionalInfo.map((attribute, index) => (
                    attribute[0] === 'pn_number' ? (
          <div className="px-2 py-1 font-medium text-white bg-gray-500">{attribute[0]} : {attribute[1]}</div>
                              ) : null
                  ))
                }
            <div className="overflow-y-auto flex-grow mb-2">
            {/* Iterate over task details */}
            {task.taskDetails.map((detail, detailIndex) => (

            <div key={detailIndex} className="m-2 border-t-2">
               <h1>Task ID: {detail.id}</h1>
            {/* Render comment for each detail */}
            {detail.comment.map((item, commentIndex) => (
              <div key={commentIndex} className="mb-2">
                <strong>{item.name}:</strong> {item.comment}
              </div>
            ))}
               <input
            type="text"
            className="border rounded p-2 w-full"
            placeholder="Type your comment..."
          />
          {/* Position the chat input at the bottom */}
          <div className="absolute bottom-0 left-0 w-full"></div>
            </div>

            ))}
            </div>
        </div>
      ))}
    </>
  );
};

export default ChatBox;
