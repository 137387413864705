import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import ThreeModelViewer from "./components/ThreeModelViewer";
import KanbanPage from "./pages/KanbanPage";

function App() {
  return (
       <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/kanban" element={<KanbanPage />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
