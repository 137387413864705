function getFoidValue(attributes) {
    for (const [key, value] of attributes) {
        if (key === "foid") {
            return value;
        }
    }
    return null; // Return null if "foid" key is not found
}


function getObjectsByFoid(data, foid) {
    return data.filter(obj => obj.foid === foid);
}

module.exports = { getObjectsByFoid,getFoidValue };